import RequestManager from "../function/request/RequestManager";
import { uniqBy } from "../function/lodash";

export class OldChatClass {
  #id = undefined;
  isLoading = false;
  currentMessage = undefined;
  acl = undefined;
  chatInfo = undefined;
  messagesList = [];
  messagesCount = 0;
  limit = 0;

  constructor(id) {
    this.#id = id;
  }

  get message() {
    return this.currentMessage;
  }

  set message(message) {
    this.currentMessage = message.trim();
  }

  get messagesList() {
    return this.messagesList;
  }

  async showMore() {
    if (this.messagesList.length < this.messagesCount) {
      this.isLoading = true;
      this.limit += 20;

      await this.getMessagesList();

      this.isLoading = false;
    }
  }

  async getMessagesList() {
    const response = await RequestManager().chatApi.listChatMessages(this.#id);
    const responseUsers = await RequestManager().getUsersListAll();

    this.messagesCount = response.count;
    let messages = response.items;
    let users = responseUsers.results;

    messages.forEach(el => {
      // console.log("el",el);
      // console.log("users",users)
      const id = el.author.id;

      let first_name = "";
      let last_name = "";

      let user = users.find(item => +item.id == +id);

      if (user) {
        first_name = user.first_name;
        last_name = user.last_name;
      }

      el.author = {
        id: id,
        first_name: first_name,
        last_name: last_name
      };
    });

    if (messages.length) {
      this.messagesList = uniqBy(messages, "timestamp").filter(
        el => !el.isBuffered
      );
    }
  }

  async sendMessage(message = "", authorId = "", file = undefined) {
    const bufferMessage = {
      author: {
        id: authorId ? authorId : undefined
      },
      text: message,
      file: file,
      isBuffered: true
    };
    this.messagesList.unshift(bufferMessage);

    let data = {
      text: message,
      replyId: "",
      author: {
        id: authorId.toString()
      }
    };
    if (file) {
      const uploadedFileResponse = await RequestManager().filesApi.upload(
        "/",
        file
      );
      data.attachments = [
        {
          title: uploadedFileResponse[0].name,
          objectType: "FileAttachment",
          path: uploadedFileResponse[0].path
        }
      ];
      await RequestManager().chatApi.sendMessage(this.#id, data);
    } else {
      await RequestManager().chatApi.sendMessage(this.#id, data);
    }
  }

  async addUserToChat(userId) {
    if (!userId) {
      return;
    }
    let newPermissions = this.chatInfo.permissions;
    newPermissions.push({
      user: {
        id: userId.toString()
      },
      roles: ["canWrite", "canRead"]
    });
    await RequestManager().chatApi.editChat(this.#id, {
      title: this.chatInfo.title,
      permissions: newPermissions
    });
  }

  async deleteUserFromChat(userId) {
    if (!userId) {
      return;
    }
    let newPermissions = this.chatInfo.permissions;
    const foundUserIdx = this.chatInfo.permissions.findIndex(
      user => Number(user.user.id) === Number(userId)
    );
    if (foundUserIdx > -1) {
      this.chatInfo.permissions.splice(foundUserIdx, 1);
    }
    await RequestManager().chatApi.editChat(this.#id, {
      title: this.chatInfo.title,
      permissions: newPermissions
    });
  }

  async getChat() {
    this.chatInfo = await RequestManager().chatApi.getChat(this.#id);
  }

  // НЕ НУЖЕН
  async getAcl() {
    return null;
  }
}

// =============================================================================================================== //

// Эта реализация не нужна и должна быть удалена !!!
export class ChatClass {
  #id = undefined;
  isLoading = false;
  currentMessage = undefined;
  acl = undefined;
  chatInfo = undefined;
  messagesList = [];
  messagesCount = 0;
  limit = 30;

  constructor(id) {
    this.#id = id;
  }

  get message() {
    return this.currentMessage;
  }

  set message(message) {
    this.currentMessage = message.trim();
  }

  get messagesList() {
    return this.messagesList;
  }

  async getMessagesList() {
    const response = await RequestManager().getMessages(this.#id, this.limit);
    // const response = await RequestManager().chatApi.listChatMessages(this.#id);

    this.messagesCount = response.count;
    let messages = response.items;

    if (messages.length) {
      this.messagesList = uniqBy(...messages, "timestamp").filter(
        el => !el.isBuffered
      );
    }
  }

  async getAcl() {
    // this.acl = await RequestManager().getAcl("chats", "chat", this.#id);
    return null;
  }

  async getChat() {
    // this.chatInfo = await RequestManager().getChatById(this.#id);
    this.chatInfo = await RequestManager().chatApi.getChat(this.#id);
  }

  async showMore() {
    if (this.messagesList.length < this.messagesCount) {
      this.isLoading = true;
      this.limit += 20;

      await this.getMessagesList();

      this.isLoading = false;
    }
  }

  async sendMessage(message = "", authorId = "", file = undefined) {
    const bufferMessage = {
      author: {
        id: authorId ? authorId : undefined
      },
      text: message,
      file: file,
      isBuffered: true
    };
    this.messagesList.unshift(bufferMessage);

    const data = new FormData();
    data.append("text", message);
    if (file) {
      data.append("file", file);
    }

    await RequestManager().sendMessage(this.#id, data);
  }

  async deleteChat() {
    await RequestManager().deleteChat(this.#id);
  }

  async addUserToChat(userId) {
    this.acl[userId] = ["chat_write", "chat_read"];
    await RequestManager().putAcl("chats", "chat", this.#id, this.acl);
    await this.getChat();
  }

  async deleteUserFromChat(userId) {
    this.acl[userId] = [];
    await RequestManager().putAcl("chats", "chat", this.#id, this.acl);
    await this.getChat();
  }

  // updateChat() {
  //   this.getChat();
  //   this.getMessagesList();
  // }
}

// ACL
// есть 3 permission - chat_admin, chat_write и chat_read
// app_label = "chats", model - "chat"
