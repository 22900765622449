<template>
  <div class="chat-container full-height">
    <b-modal
      id="users-list"
      hide-footer
      title="Список пользователей"
      v-if="chat && chat.chatInfo"
    >
      <template>
        <div
          v-for="(user, idx) in chat.chatInfo.permissions"
          :key="idx"
          class="d-flex align-items-center justify-content-between mb-1"
        >
          <div
            class="left-side d-flex align-items-center pseudo-link"
            @click="goToUserPage(user.user)"
          >
            <div :class="{ 'font-bold': user.roles.includes('admin') }">
              {{ user.user.firstName }} {{ user.user.lastName }}
            </div>
            <div class="ml-1 email">{{ user.user.email }}</div>
          </div>
          <!-- <div class="right-side">
            <img v-if="currentUserIsChatAdmin" :src="minusIcon" width="24" height="24" class="pseudo-link" title="Удалить пользователя из чата" @click="chat.deleteUserFromChat(user.user.id)" />
          </div> -->
        </div>
        <!-- <div class="mt-4 d-flex align-items-center justify-content-center">
          <div class="btn btn-second w-100">Добавить пользователей</div>
        </div> -->
      </template>
      <!-- <transition-group name="list" mode="out-in">
        <div
          v-for="user in chat.chatInfo.permissions"
          :key="user.id"
          class="d-flex justify-content-between mb-2"
        >
          <div>
            {{ getAuthor(user) }}
            <span class="email">{{ user.email }}</span>
          </div>
          <span
            class="users-list__action users-list__action-delete c-pointer"
            v-if="currentUserIsChatAdmin && user.id !== currentUser.id"
            @click="chat.deleteUserFromChat(user.id)"
            >-</span>
        </div>
      </transition-group>
      <div v-if="currentUserIsChatAdmin">
        <hr />
        <label class="font-weight-bold">Добавить пользователей:</label>
        <transition-group name="list">
          <div
            v-for="user in filteredUsers"
            :key="user.id"
            class="d-flex justify-content-between mb-2"
          >
            <div>
              {{ getUserInfo(user) }}
              <span class="email">{{ user.email }}</span>
            </div>

            <span
              class="users-list__action users-list__action-add c-pointer"
              @click="chat.addUserToChat(user.id)"
              >+</span
            >
          </div>
        </transition-group>
      </div> -->
    </b-modal>

    <b-modal
      hide-footer
      title="Загрузка файла"
      id="file-modal"
      @cancel="closeFileModal"
      @hide="closeFileModal"
    >
      <b-form @submit="sendMessage">
        <label>Выберите файл</label>
        <b-form-file
          v-model="selectedFile"
          placeholder="Файл не выбран"
          browse-text="Обзор"
          ref="fileinput"
        />
        <hr />
        <label>Описание</label>
        <b-input v-model="chatMessage" />

        <b-button
          class="mt-4 create-chat-btn"
          type="submit"
          @click.prevent="sendMessage()"
        >
          Отправить
        </b-button>
      </b-form>
    </b-modal>

    <div
      class="d-flex justify-content-center align-items-center pt-5"
      v-if="isShowLoadingSpinner"
    >
      <b-spinner />
    </div>

    <div v-else class="full-height d-flex flex-column">
      <div
        v-if="showTopPanel"
        class="chat-settings d-flex justify-content-between align-items-center"
      >
        <div class="position-relative">
          <h3 class="mb-0">
            {{ chat && chat.chatInfo ? chat.chatInfo.title : "Загрузка" }}
            <help-popover
              v-if="helpPopovers.PopoverOfCount"
              :message="helpPopovers.PopoverOfCount"
              class="ml-2 help-icon"
              :is-inline-block="true"
              style="position: relative; top: -10px; right: 10px"
            />
          </h3>
          <p class="mb-0 c-pointer" @click="showAllUsers">
            {{
              chat.chatInfo ? `Кол-во участников: ${usersCount}` : "Загрузка"
            }}
            <help-popover
              v-if="helpPopovers.PopoverOfNameChat"
              :message="helpPopovers.PopoverOfNameChat"
              class="ml-2 help-icon"
              :is-inline-block="true"
              style="position:relative; bottom: 5px; right: 7px"
            />
          </p>
        </div>

        <div class="c-pointer setting-admin" v-if="currentUserIsChatAdmin">
          <!-- <span @click="isShowSettings = true">...</span> -->
          <img
            :src="adminIcon"
            class="pseudo-link"
            width="24"
            height="24"
            @click="isShowSettings = true"
          />

          <div
            v-if="isShowSettings"
            class="setting-admin__panel"
            v-click-outside="hideSettingsPanel"
          >
            <div class="setting-admin__panel-item" @click="editChat">
              Редактировать чат
            </div>
            <div class="setting-admin__panel-item" @click="deleteChat">
              Удалить чат
            </div>
          </div>
        </div>
      </div>
      <div
        class="messages-wrapper flex-1 py-2 custom-scrollbar data-messages-wrapper"
        ref="abc"
      >
        <div ref="messageContainer">
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="d-flex flex-column"
          >
            <div
              class="text-center mt-3 mb-3 check-new-date"
              v-if="checkNewDay(message)"
            >
              {{ checkNewDay(message) }}
            </div>

            <div
              class="chat-message position-relative"
              :class="
                currentUser.id === parseInt(message.author.id)
                  ? 'my-message'
                  : ''
              "
            >
              <help-popover
                v-if="helpPopovers.PopoverOfExampleMessage"
                :message="helpPopovers.PopoverOfExampleMessage"
                class="ml-2 help-icon"
                :is-inline-block="false"
                style="right: -15px; top: -15px"
              />
              <help-popover
                v-if="
                  helpPopovers.PopoverOfExampleMyMessage &&
                    currentUser.id === message.author.id
                "
                :message="helpPopovers.PopoverOfExampleMyMessage"
                class="ml-2 help-icon my-message"
                :is-inline-block="false"
                style="right: -15px; top: -15px"
              />

              <div
                class="chat-message__author pseudo-link"
                @click="goToAuthorPage(message.author)"
              >
                {{
                  message.author.first_name && message.author.last_name
                    ? "  " +
                      message.author.first_name +
                      " " +
                      message.author.last_name +
                      " "
                    : " "
                }}
              </div>

              <a
                :href="getImage(message.attachments[0].url)"
                v-if="message.attachments && message.attachments.length"
                target="_blank"
              >
                <img
                  class="chat-message__img"
                  v-if="message.attachments && message.attachments.length"
                  :src="
                    message.attachments[0].url ? message.attachments[0].url : ''
                  "
                  alt="Прикрепленный файл"
                />
              </a>

              <span class="chat-message__text"> {{ message.text }} </span>
              <span class="text-right chat-message__timestamp">
                {{
                  message.timestamp ? formatDate(message.timestamp) : "Отправка"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer p-2">
        <div class="position-relative chat-component__input2">
          <div
            class="attach-btn"
            @click="showFileModal"
            title="Прикрепить файл"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Capa_1"
              x="0px"
              y="0px"
              width="913.9px"
              height="913.9px"
              viewBox="0 0 913.9 913.9"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M849.4,248.6c-2.8-69-32.3-132.9-83.1-179.8c-24-22.1-51.3-39.3-81.2-51.1C655.201,6,623.601,0,591.101,0   c-3.5,0-7.1,0.1-10.6,0.2c-69,2.8-132.8,32.3-179.8,83.2L109.801,397.3c-9.3,10.101-8.8,25.801,1.2,35.2l20.2,19   c10.1,9.5,26,9,35.5-1.2l291.1-314.1c33.2-36,81.9-56.7,133.5-56.7c46.4,0,89.9,16.5,122.3,46.5c35.5,32.8,56.2,77.4,58.101,125.8   c1.899,48.3-14.9,94.3-47.5,129.6l0,0l0,0l-395.4,422.1c-19.8,21.4-48.7,33.7-79.4,33.7c-27.6,0-53.5-9.8-72.8-27.7   c-21.1-19.5-33.3-46-34.6-74.8c-1.2-28.7,8.8-56.1,28.1-77l0,0l0,0c0,0,368-392.7,368.601-393.2c32.199-33.4,82.699,15.2,50.5,48.6   c-54.7,56.7-195.2,208.4-264,282.9c-9.301,10.101-8.801,25.8,1.199,35.2l20.2,19c10.101,9.5,26,9,35.5-1.2l264.9-285.6   c41.899-45.4,39.1-116.5-6.4-158.5c-20.7-19.1-47.8-29.7-76.1-29.7c-15.3,0-30.101,3-44.101,9c-14.399,6.1-27.199,15.1-37.8,26.7   l0,0l0,0l-369.3,393.8c-69.3,75.1-64.5,192.6,10.6,262c33,30.5,77.5,47.2,125.3,47.2c53.1,0,102.8-20.9,136.4-57.3l0,0l0,0   l395.4-422.101C827.801,383.7,852.101,317.7,849.4,248.6z"
                />
              </g>
            </svg>
            <help-popover
              v-if="helpPopovers.PopoverOfAttachingAFile"
              :message="helpPopovers.PopoverOfAttachingAFile"
              class="ml-2 help-icon"
              :is-inline-block="false"
              style="left: 40px; top: 0"
            />
          </div>
          <b-textarea
            no-resize
            v-model="chatMessage"
            rows="4"
            placeholder="Введите ваше сообщение"
            @keydown.enter.prevent=""
            @keyup.ctrl.enter="() => (chatMessage = chatMessage + '\n')"
            @keyup.enter.prevent.exact="sendMessage"
            :autofocus="true"
          />
          <help-popover
            v-if="helpPopovers.PopoverOfAreaToWriteMessages"
            :message="helpPopovers.PopoverOfAreaToWriteMessages"
            class="ml-2 help-icon"
            :is-inline-block="true"
            style="position: relative; top: -25px"
          />
          <div class="send-button" @click="sendMessage">Отправить</div>
          <help-popover
            v-if="helpPopovers.PopoverOfSendingMessages"
            :message="helpPopovers.PopoverOfSendingMessages"
            class="ml-2 help-icon"
            :is-inline-block="false"
            style="right: 3px; top: 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChatClass, OldChatClass } from "@/classes/Chat";
import { debounce } from "@/function/lodash";
import moment from "moment";
import helpPopover from "../HelpPopover";
import MinusIcon from "@/resource/img/minus_form.svg";
import PlusIcon from "@/resource/img/plus_form.svg";
import AdminIcon from "@/resource/img/admin.svg";

export default {
  components: {
    helpPopover
  },
  name: "Chat",
  props: {
    id: Number,
    users: Array,
    showTopPanel: {
      type: Boolean,
      required: false,
      default: true
    },
    isUseOldChat: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowDetailInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowSearchBar: {
      type: Boolean,
      required: false,
      default: false
    },
    chatKey: Number
  },
  data() {
    return {
      helpPopovers: {
        PopoverOfAttachingAFile: "Отправить (прикрепить к чату) файл",
        PopoverOfAreaToWriteMessages:
          "Поле для сообщения (при нажатии на кнопку Enter, произойдет отправка сообщения)",
        PopoverOfSendingMessages: "Кнопка отправки сообщения",
        PopoverOfCount: "Количество участников в данном чате",
        PopoverOfNameChat: "Название чата",
        PopoverOfExampleMyMessage: "Пример сообщения в чате от нас собеседнику",
        PopoverOfExampleMessage: "Пример сообщения в чате от собеседника"
      },
      chat: this.isUseOldChat
        ? new OldChatClass(this.id)
        : new ChatClass(this.id),
      intervalId: undefined,
      chatMessage: undefined,
      selectedFile: null,
      isShowSettings: false,
      isShowLoadingSpinner: true,
      minusIcon: MinusIcon,
      plusIcon: PlusIcon,
      adminIcon: AdminIcon
    };
  },
  directives: {
    scroll: {
      inserted: function(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener("scroll", f);
          }
        };
        window.addEventListener("scroll", f);
      }
    },
    clickOutside: {
      bind: function(element, binding, vnode) {
        element.clickOutsideEvent = function(event) {
          if (!(element === event.target || element.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("mousedown", element.clickOutsideEvent);
      },
      unbind: function(element) {
        document.body.removeEventListener(
          "mousedown",
          element.clickOutsideEvent
        );
      }
    }
  },
  computed: {
    filteredUsers() {
      const usersInChatIds = this.chat?.chatInfo?.users?.map(user => user.id);
      return this.users?.filter(el => !usersInChatIds?.includes(el.id));
    },
    usersCount() {
      return this.chat?.chatInfo?.permissions?.length;
    },

    scrollDebounced() {
      return debounce(this.checkScrollPosition, 500);
    },

    messages() {
      const messages = this.chat?.messagesList;
      return messages.reverse();
    },

    currentUser() {
      return this.$store.getters?.currentUser;
    },

    currentUserIsChatAdmin() {
      /* return this.chat?.acl
        ? this.chat.acl?.[this.currentUser?.id]?.includes("chat_admin")
        : false; */
      let currentUserChatInfo = this.chat.chatInfo.permissions.find(
        user => Number(user.user.id) === this.currentUser.id
      );
      if (currentUserChatInfo) {
        if (currentUserChatInfo.roles.includes("admin")) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    isShowLoadingSpinner() {
      this.scrollToBottom();
    }
  },
  methods: {
    goToUserPage(user) {
      location.href = `/colleagues-contacts#${user.firstName} ${user.lastName}`;
    },
    goToAuthorPage(author) {
      location.href = `/colleagues-contacts#${author.first_name} ${author.last_name}`;
    },
    getImage(src) {
      //   return process.env.VUE_APP_URL + src;
      return src;
    },

    getUserInfo(user) {
      return `${user.last_name ? user.last_name : ""} ${
        user.first_name ? user.first_name : ""
      }`;
    },
    editChat() {
      this.isShowSettings = false;
      this.$emit("editChat", this.id);
    },
    deleteChat() {
      this.isShowSettings = false;
      this.$emit("deleteChat", this.id);
    },
    hideSettingsPanel() {
      this.isShowSettings = false;
    },
    /*
    checkScrollPosition(event) {
      let cltHeight = event.target?.clientHeight;
      let scrHeight = event.target?.scrollHeight;
      let scrTop = event.target?.scrollTop;
      let percentage = ((cltHeight - scrTop) / scrHeight).toFixed(2);

      if (percentage >= 0.8) {
        this.chat.showMore();
      }
    },
    */
    checkNewDay(message) {
      let date;
      if (message && message.timestamp) {
        const index = this.messages.indexOf(message);
        const messageTime = new Date(message.timestamp).toLocaleDateString();
        const previousChatMessage = this.messages[index - 1];
        if (previousChatMessage && messageTime) {
          let previousChatMessageDatetime = new Date(
            previousChatMessage.timestamp
          ).toLocaleDateString();
          if (messageTime === previousChatMessageDatetime) {
            date = false;
          } else {
            date = messageTime;
          }
        } else {
          date = messageTime;
        }
      }
      return date;
    },
    showFileModal() {
      this.$bvModal.show("file-modal");
    },
    showAllUsers() {
      this.$bvModal.show("users-list");
    },
    closeFileModal() {
      this.selectedFile = null;
      this.chatMessage = "";
      this.$bvModal.hide("file-modal");
    },
    getAuthor(item) {
      let label = "Не указан";
      label =
        item.first_name?.length > 2 && item.last_name?.length > 2
          ? `${item.first_name} ${item.last_name}`
          : item.email;
      return label;
    },
    async sendMessage() {
      if (this.chatMessage?.length || this.selectedFile) {
        const messageForBuffer = this.chatMessage;

        this.chatMessage = "";
        this.scrollToBottom();
        const file = this.selectedFile ? this.selectedFile : undefined;

        await this.chat.sendMessage(
          messageForBuffer,
          this.currentUser.id,
          file
        );

        this.update();

        this.closeFileModal();

        this.scrollToBottom();
      }
    },
    formatDate(timestamp) {
      return moment(timestamp).format("lll");
    },
    async update() {
      if (this.id) {
        await this.chat.getMessagesList();
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      /* if (this.messages?.length && this.$refs.messageContainer) {
        this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
      } */
      const messagesWrapper = document.getElementsByClassName(
        "messages-wrapper"
      )[0];
      if (messagesWrapper) {
        messagesWrapper.scrollTop = messagesWrapper.scrollHeight;
      }
    }
  },
  async created() {
    this.isShowLoadingSpinner = true;
    try {
      await this.chat.getChat();
      await this.chat.getMessagesList();
      // await this.chat.getAcl();
    } finally {
      this.isShowLoadingSpinner = false;
      this.scrollToBottom();
    }
    this.$watch("chatKey", () => {
      this.update();
    });
  },
  mounted() {
    // this.intervalId = setInterval(this.update, 3000);
    // setTimeout(() => this.scrollToBottom(), 500);
  },
  updated() {
    this.scrollToBottom();
  },
  beforeDestroy() {
    // clearInterval(this.intervalId);
  }
};
</script>

<style scoped lang="scss">
.chat-settings {
  border-bottom: 1px #dedede solid;
  padding: 0 10px;
  background-color: var(--main-card-color);
}

.chat-component {
  background-color: var(--bg-chat-gradient);
  background-image: var(--chat-gradient);
  padding-bottom: 10px;
  border-radius: 4px;

  &__messages {
    overflow: auto;
    /* height: calc(
      100vh - 534px
    ) !important; // размер чата когда информация о задаче развернута */
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1rem;
    flex: 1;
  }

  &__messages2 {
    overflow: auto;
    /* max-height: calc(
      100% - 475px
    ) !important; // размер чата когда информация о задаче свернута */
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1rem;
    flex: 1;
  }

  &__messages.no-message {
    h3 {
      text-align: center;
      color: #fff;
    }

    flex-direction: column;
    font-weight: bold;
    color: #994d0c;
    //   background-image: url("");
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  &__messages2.no-message {
    h3 {
      text-align: center;
      color: #fff;
    }

    flex-direction: column;
    font-weight: bold;
    color: #994d0c;
    //   background-image: url("");
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  &__input {
    padding: 20px;
    margin-top: -72px; // поднимается кнопка отправки сообщения
    display: flex;
    justify-content: space-between;
    align-items: center;

    .attach-btn {
      cursor: pointer;
      margin-right: 17px;
      background-position: center;
      padding: 5px;

      svg {
        width: 25px;
        height: 25px;
        fill: #fff;
        stroke: black;
      }
    }

    .send-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-left: 15px;
      padding: 10px 20px;
      background-color: var(--help-popover-color);
      color: var(--main-text-color-inverse);
      border-radius: 5px;
      transition: all 0.3s ease;
      align-items: center;

      &:hover {
        background-color: var(--help-popover-color);
      }
    }
  }

  &__input2 {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .attach-btn {
      cursor: pointer;
      margin-right: 17px;
      background-position: center;
      padding: 5px;

      svg {
        width: 25px;
        height: 25px;
        fill: #fff;
        stroke: black;
      }
    }

    .send-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-left: 15px;
      padding: 10px 20px;
      background-color: var(--help-popover-color);
      color: var(--main-text-color-inverse);
      border-radius: 5px;
      transition: all 0.3s ease;
      align-items: center;

      &:hover {
        background-color: var(--help-popover-color);
      }
    }
  }
}

.check-new-date {
  opacity: 0.7;
  background-color: var(--main-card-color);
  display: flex;
  padding: 5px 20px;
  align-self: center;
  border-radius: 15px;
}

.author {
  opacity: 0.7;
  background-color: var(--main-card-color);
  display: flex;
  padding: 5px 20px;
  align-self: center;
  border-radius: 15px;
}

.chat-message {
  display: flex;
  background-color: var(--main-card-color);
  padding: 10px 20px;
  border-radius: 15px;
  margin: 0 20px 20px 20px;
  font-size: 14px;
  max-width: 65%;
  width: fit-content;
  flex-direction: column;

  &__text {
    letter-spacing: 0.5px;
  }

  &__timestamp {
    opacity: 0.5;
  }

  &__author {
    font-weight: bold;
  }

  &__img {
    height: 150px;
    width: 150px;
    max-width: 300px;
    max-height: 450px;
  }
}

.my-message {
  align-self: flex-end;
}

.create-chat-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #006989;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  align-items: center;
  border: 1px transparent solid;

  &:hover {
    background-color: #006989ad;
    border: 1px #dedede solid;
  }
}

.email {
  opacity: 0.7;
  font-size: 13px;
}

.setting-admin {
  position: relative;

  &__panel {
    position: absolute;
    background-color: white;
    border: 1px solid var(--main-card-color);
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    border-radius: 5px;
    width: 250px;
    top: 30px;
    right: 0;

    &-item {
      padding: 10px;
      &:hover {
        background-color: #dedede;
      }
    }
  }
}

.users-list__action {
  margin-left: 5px;
  display: inline-flex;
  padding: 0 10px;
  border: 1px transparent solid;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(34, 60, 80, 0.2);
  }

  &:active {
    transform: scale(1.2);
  }

  &-delete {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &-add {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 300ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  //transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  //transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  //transform: scaleY(0);
  transform-origin: center top;
}

.create-chat-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #006989;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  align-items: center;
  border: 1px transparent solid;

  &:hover {
    background-color: #006989ad;
    border: 1px #dedede solid;
  }
}

.help-icon {
  position: absolute;
}

.form-control {
  background-color: var(--main-card-color);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.full-height {
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.messages-wrapper {
  overflow: auto;
}

.chat-message__text {
  white-space: pre-line !important;
}

.pseudo-link {
  cursor: pointer;
}

.font-bold {
  font-weight: 600;
}
</style>
